<template>
  <v-dialog
    v-model='isOpen'
    max-width='800px'
    persistent
  >
    <v-card class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center pb-4 mb-0'>Schedule Voting Day</v-card-title>
      <v-radio-group
        v-model='selectedDate'
        class='ml-6'
      >
        <v-radio
          v-for='date in availableDates'
          :key='date'
          :label='date'
          :value='date'
          class='pb-2'
        >
          {{ date }}
        </v-radio>
      </v-radio-group>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click='closeDialog'
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled='!selectedDate'
          :loading='loadingSubmit'
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click='submitVoting'
        >
          Schedule
        </v-btn>
        <v-btn
          :disabled='selectedIds.length === 1 && !selectedDate'
          :loading='loadingSubmitDelete'
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click='deleteVoting'
        >
          Delete voting day
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'ScheduleVotingDialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    selectedIds: {
      type: Array,
      required: true
    },
    availableDates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedDate: '',
      loadingSubmit: false,
      loadingSubmitDelete: false,
      childElement: {},
      childElementDelete: {},
      childElementDeleteId: null
    }
  },
  computed: {
    ...mapState({
      selectedElection: s => s.electionStore.selectedElection,
      subordinates: s => s.memberStore.subordinates
    }),
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    isOpen() {
      if (this.selectedIds.length === 1 && this.isOpen) {
        let element = this.subordinates.find(el => el.id === this.selectedIds[0])
        if (!element) {
          element = this.isChildren(this.subordinates)
        }
        this.selectedDate = element.dayScheduledToVote
        element = []
      }
    }
  },
  methods: {
    ...mapActions({
      submitVotingRequests: 'employeeStore/submitVotingRequests',
      deleteVotingRequests: 'employeeStore/deleteVotingRequests'
    }),
    async submitVoting() {
      this.loadingSubmit = true
      const data = {
        electionId: this.selectedElection.id,
        employeeId: this.selectedIds,
        voteDate: this.selectedDate,
        user_who_approved_id: this.user.id,
        is_approved: true
      }
      await this.submitVotingRequests(data)
      this.loadingSubmit = false
      this.closeDialog()
      this.$emit('update-table')
    },
    isChildrenDelete(arr) {
      arr.forEach(el => {
        if (el.children.length) {
          const res = el.children.find(elChild => elChild.id === this.childElementDeleteId)
          if (res) {
            this.childElementDelete = res
          } else this.isChildrenDelete(el.children)
        }
      })
      return this.childElementDelete
    },
    async deleteVoting() {
      this.loadingSubmitDelete = true
      let promises = []
      for (const id of this.selectedIds) {
        let element = this.subordinates.find(el => el.id === id)
        if (!element) {
          this.childElementDeleteId = id
          element = this.isChildrenDelete(this.subordinates)
        }
        if (element.voteRequestId !== null) {
          const orderPromise = this.deleteVotingRequests(element.voteRequestId)
          promises.push(orderPromise)
        }
        element = []
      }
      await Promise.all(promises)
      this.loadingSubmitDelete = false
      this.closeDialog()
      this.$emit('update-table')
    },
    closeDialog() {
      this.selectedDate = ''
      this.$emit('close-dialog')
    },
    isChildren(arr) {
      arr.forEach(el => {
        if (el.children.length) {
          const res = el.children.find(elChild => elChild.id === this.selectedIds[0])
          if (res) {
            this.childElement = res
          } else this.isChildren(el.children)
        }
      })
      return this.childElement
    }
  }
}
</script>
