<template>
  <v-dialog
    v-model='isOpen'
    max-width='500px'
    persistent
  >
    <v-card class='d-flex flex-column align-start pa-2'>
      <v-card-title class='text-center mb-4'>
        <v-icon
          class='mr-4 white--text'
          color='primary'
          large
        >
          mdi-home-outline
        </v-icon>
        <span>Available Election Information</span>
      </v-card-title>
      <v-card-subtitle>
        <b>Select one campaign from the campaign list below to visualize the election's statistics:</b>
      </v-card-subtitle>
      <v-radio-group
        v-model='electionId'
        class='ml-6'
      >
        <v-radio
          v-for='election in elections'
          :key='election.id'
          :label='election.description'
          :value='election.id'
          class='pb-2'
        ></v-radio>
      </v-radio-group>
      <v-card-actions class='align-self-center'>
        <v-spacer></v-spacer>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          outlined
          @click="$emit('close-dialog')"
        >
          Cancel
        </v-btn>
        <v-btn
          class='px-14 mx-4 font-weight-bold white--text'
          color='primary'
          large
          @click='onChangeElection'
        >
          Change
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'change-election-dialog',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false
    },
    elections: {
      type: Array,
      required: true,
      default: () => []
    },
    selectedElectionId: {
      required: true
    }
  },
  watch: {
    isOpen() {
      if (this.isOpen) this.electionId = this.selectedElectionId
    }
  },
  data() {
    return {
      electionId: null
    }
  },
  methods: {
    ...mapMutations('electionStore', ['SET_SELECTED_ELECTION']),
    onChangeElection() {
      const electionSelected = this.elections.find(election => election.id === this.electionId)
      this.SET_SELECTED_ELECTION(electionSelected)
      this.$emit('close-dialog')
    }
  }
}
</script>

<style scoped>

</style>
