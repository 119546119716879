var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pa-5"},[_c('v-data-table',{ref:"dataTable",staticClass:"myTable",attrs:{"expanded":_vm.expanded,"headers":_vm.organizationStatisticsHeaders,"items":_vm.subordinates,"loading":_vm.loadingSubordinates || _vm.loadingExpand,"checkboxColor":"primary","item-key":"id","selectable-key":"id","show-expand":"","show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar-title',{staticClass:"font-weight-bold mb-2"},[_vm._v(" "+_vm._s(_vm.sections.my_organization_status || 'My Organization title')+" ")]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"primary--text text-capitalize mr-6",attrs:{"href":_vm.exportEmployeesUrl,"large":"","outlined":""}},[_vm._v(" Export Employee Status "),_c('v-icon',{staticClass:"white--text",attrs:{"color":"primary","right":"","small":""}},[_vm._v(" mdi-export-variant ")])],1),(_vm.isModuleActive('voting'))?_c('v-btn',{staticClass:"primary--text text-capitalize mr-6",attrs:{"large":"","outlined":""},on:{"click":_vm.openScheduleVotingDialog}},[_vm._v(" Schedule voting ")]):_vm._e(),_c('v-btn',{staticClass:"primary--text text-capitalize",attrs:{"large":"","outlined":""},on:{"click":_vm.sendCommunication}},[_vm._v(" Send Communication ")])],1),_c('v-switch',{staticClass:"d-inline-block",attrs:{"disabled":_vm.loadingSubordinates,"label":("" + (_vm.expandValue.toString())),"loading":_vm.loadingExpand,"false-value":"Collapsed","inset":"","true-value":"Expanded"},on:{"click":_vm.expandAllTable},model:{value:(_vm.expandValue),callback:function ($$v) {_vm.expandValue=$$v},expression:"expandValue"}})]},proxy:true},{key:"header.dayScheduledToVote",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',[_vm._v(_vm._s(header.text))]),_c('v-tooltip',{attrs:{"max-width":"300","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 tooltip-icon",attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v(" mdi-eye ")])]}}],null,true)},[_c('div',{staticClass:"text-center"},[_vm._v(" If an employee is marked N/A. they do not have a voter guide for this election. ")])])],1)]}},{key:"item.data-table-expand",fn:function(data){return [(data.item.name.includes('('))?_c('v-btn',{attrs:{"loading":_vm.loadingSingleExpand && data.item.id === _vm.employeeId,"icon":""},on:{"click":function($event){return _vm.populateTreeview(data)}}},[_c('v-icon',{class:{'v-data-table__expand-icon--active': data.isExpanded }},[_vm._v(" $expand ")])],1):_vm._e()]}},{key:"item.empReg",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{
          'success--text': item.empReg === 100,
          'warning--text': item.empReg < 100 && item.empReg >= 50,
          'error--text': item.empReg < 50,
        }},[_vm._v(" "+_vm._s(item.empReg)+" % ")])]}},{key:"item.empVot",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:{
          'success--text': item.empVot === 100,
          'warning--text': item.empVot < 100 && item.empVot >= 50,
          'error--text': item.empVot < 50,
        }},[_vm._v(" "+_vm._s(item.empVot)+" % ")])]}},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{staticClass:"ma-0 td",attrs:{"colspan":headers.length}},[_c('v-treeview',{ref:"treeview",attrs:{"items":item.children,"open-all":_vm.expandTreeview,"expand-icon":"$expand","hoverable":"","item-key":"id","selectable":"","selected-color":"#5b8dd7","selection-type":"independent","transition":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"rowCustom"},[_c('div',{staticClass:"item"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"item"},[_vm._v(_vm._s(item.jobTitle))]),_c('div',{staticClass:"item",style:({ paddingLeft: item.reg==='No' && 3+'px' })},[_vm._v(" "+_vm._s(item.reg)+" ")]),_c('div',{staticClass:"item"},[_vm._v(_vm._s(item.dayScheduledToVote))]),_c('div',{staticClass:"item"},[_vm._v(_vm._s(item.votedDate))]),_c('div',{staticClass:"item",class:{
                'success--text': item.empReg === 100,
                'warning--text': item.empReg < 100 && item.empReg >= 50,
                'error--text': item.empReg < 50,
                }},[_vm._v(_vm._s(item.empReg)+" % ")]),_c('div',{staticClass:"item",class:{
                'success--text': item.empVot === 100,
                'warning--text': item.empVot < 100 && item.empVot >= 50,
                'error--text': item.empVot < 50,
                }},[_vm._v(_vm._s(item.empVot)+" % ")])])]}}],null,true),model:{value:(item.selectedTreeview),callback:function ($$v) {_vm.$set(item, "selectedTreeview", $$v)},expression:"item.selectedTreeview"}})],1)]}}]),model:{value:(_vm.selectedTable),callback:function ($$v) {_vm.selectedTable=$$v},expression:"selectedTable"}}),_c('schedule-voting-dialog',{attrs:{"available-dates":_vm.availableDates,"is-open":_vm.isScheduleVotingDialogOpen,"selected-ids":_vm.selectedEmployeesIds},on:{"update-table":_vm.initSubordinates,"close-dialog":_vm.closeDialog}}),_c('dialog-communication',{on:{"update-table":_vm.initSubordinates,"clear-selected":_vm.clearSelected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }