<template>
  <div v-frag>
    <alert/>

    <info-cards
      :allocation='allocation'
      :loading='loadingStatistics'
      :volunteering-statistics='volunteeringStatistics'
      :voting-statistics='votingStatistics'
    />

    <v-card class='mx-3 mb-3 mt-10 py-3 px-7 d-flex justify-space-between align-center'>
      <h3>
        {{ sections.url_for_registration || 'URL title' }}
        <span class='ml-2 primary--text font-weight-bold'>{{ registrationURL }}</span>
      </h3>
      <v-tooltip
        v-model='isUrlCopied'
        color='success'
        top
      >
        <template #activator={}>
          <v-btn
            class='text-capitalize'
            color='primary'
            outlined
            @click='copyUrl'
          >
            Copy URL
            <v-icon>mdi-clipboard</v-icon>
          </v-btn>
        </template>
        <span>Copied!</span>
      </v-tooltip>
    </v-card>

    <change-election
      :elections='userElections'
      :loading='loadingElections'
      :selectedElection='selectedElection'
      :title='sections.change_election_overview'
      class='mx-3 my-10'
    />

    <card-task
      v-if="user.id_rol === 5 && isModuleActive('voting')"
      :election='selectedElection'
    />
    <time-limits
      v-if="user.id_rol === 2 && isModuleActive('volunteering')"
      :loading='loadingMemberHours'
      :title="sections.set_time_limits || 'Set limit title'"
    />

    <organization-status class='mx-3 mt-10'/>

    <employee-registration-column
      v-if="isModuleActive('voting')"
      class='my-10 mx-3'
      @show-section-employees-info='showSections'
    />

    <div ref='sectionEmployeeByVoteAnchor'></div>
    <v-card
      v-if='showSectionEmployeesByVoteStatus'
      ref='sectionEmployeeByVote'
      class='mx-3'
    >
      <v-data-table
        v-model='selectedEmployeeByVoteStatus'
        :headers='employeesByVoteStatusHeaders'
        :items='employeesByVoteStatus'
        :loading='loadingEmployeeByVoteStatus'
        checkboxColor='primary'
        class='elevation-1 pa-4 mt-4'
        item-key='id'
        loading-text='Loading employees... Please wait'
        show-select
      >
        <template #top>
          <v-toolbar
            class='mb-5'
            flat
          >
            <v-row class='pt-5 pb-5'>
              <v-col
                class='py-0'
                md='6'
              >
                <v-toolbar-title class='font-weight-bold mt-3'>{{ sectionEmployeesByVoteTitle }}</v-toolbar-title>
              </v-col>
              <v-col md='6'>
                <v-btn
                  :disabled='selectedEmployeeByVoteStatus.length === 0'
                  class='primary--text text-capitalize mr-6'
                  large
                  outlined
                  @click='openDatePickerDialogFromEmployeesByVoteStatus()'
                >
                  Schedule voting
                </v-btn>
                <v-btn
                  :disabled='selectedEmployeeByVoteStatus.length === 0'
                  class='primary--text text-capitalize'
                  large
                  outlined
                  @click='sendCommunicationFromSelectedEmployeeByVote(selectedEmployeeByVoteStatus)'
                >
                  Send Communication
                </v-btn>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>

    <v-row class='my-10 mx-3'>
      <v-col
        v-if="isModuleActive('donations')"
        class='pa-0 pr-md-6 mb-10'
        md='7'
      >
        <donation-statistics
          :loading='loadingStatistics'
          :statistics='donationStatistics'
        />
      </v-col>
      <v-col
        v-if="isModuleActive('voting')"
        :class="{
          'pr-10': !isModuleActive('donations'),
          'col-md-6': !isModuleActive('donations'),
          'mb-10': isModuleActive('donations')
        }"
        class='pa-0 col-md-5'
      >
        <voting-record-cycle @show-section-employees-info='showSections'/>
      </v-col>
      <v-col
        :class="{
          'offset-7': isModuleActive('voting') && isModuleActive('donations'),
          'col-md-6': !isModuleActive('donations')
        }"
        class='pa-0 col-12 col-md-5'
      >
        <v-card>
          <v-card-text class='d-flex flex-column align-center pa-0'>
            <v-card-title class='pa-0 mb-3'>{{ sections.organization_overview || 'Overview title' }}</v-card-title>
            <v-card-subtitle class='mr-6'>
              Total Employees:
              <span class='font-weight-bold ml-4'>
                {{ totalEmployees }}
              </span>
            </v-card-subtitle>
          </v-card-text>
          <v-data-table
            :headers='organizationHeaders'
            :items='organization'
            :loading='loadingOrganization'
            class='my-table mx-6 elevation-2'
            hide-default-footer
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <schedule-voting-dialog
      :available-dates='availableDates'
      :is-open='isScheduleVotingDialogOpen'
      :selected-ids='selectedForSchedulingVoteEmployeeIds'
      @close-dialog='closeDialog'
    />
    <!--      @update-table='initSubordinates' -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { HTTP } from '../../../http-common'
import helper from '../../services'
import Alert from '../../components/Alert.vue'
import CardTask from '../campaing/components/CardTask'
import VotingRecordCycle from '@/components/charts/voting-record-cycle'
import DonationStatistics from '@/components/donation-statistics'
import InfoCards from '@/components/info-cards'
import EmployeeRegistrationColumn from '@/components/charts/employee-registration-column'
import TimeLimits from '@/views/member/components/time-limits/time-limits'
import OrganizationStatus from '@/components/organization-status/organization-status'
import ChangeElection from '@/components/change-election/change-election'
import ScheduleVotingDialog from '@/components/organization-status/dialogs/schedule-voting-dialog'

export default {
  components: {
    ScheduleVotingDialog,
    OrganizationStatus,
    TimeLimits,
    DonationStatistics,
    VotingRecordCycle,
    Alert,
    CardTask,
    InfoCards,
    EmployeeRegistrationColumn,
    ChangeElection
  },
  data() {
    return {
      availableDates: [],
      isScheduleVotingDialogOpen: false,
      loadingStatistics: true,
      isUrlCopied: false,
      loadingEmployeeByVoteStatus: true,
      selectedForSchedulingVoteEmployeeIds: [],
      sectionEmployeesByVoteTitle: '',
      showSectionEmployeesByVoteStatus: false,
      employeesByVoteStatus: [],
      employeesByVoteStatusHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Scheduled Day', value: 'dayScheduledToVote' },
        { text: 'Voted Date', value: 'votedDate' }
      ],
      selectedEmployeeByVoteStatus: [],
      organizationHeaders: [
        {
          text: 'Job Title',
          align: 'start',
          sortable: false,
          value: 'jobTitle',
          divider: true
        },
        {
          text: 'Amount',
          align: 'start',
          sortable: false,
          value: 'amount',
          divider: true
        },
        {
          text: '%',
          align: 'start',
          sortable: false,
          value: 'percentage',
          divider: true
        }
      ],
      organization: [],
      loadingOrganization: true,
      totalEmployees: null,
      selectedForCommIds: [],
      loadingElections: true,
      loadingMemberHours: true
    }
  },
  computed: {
    ...mapState({
      sections: s => s.cmsStore.sections,
      registrationURL: s => s.employeeStore.registrationURL,
      donationStatistics: s => s.memberStore.donationStatistics,
      votingStatistics: s => s.memberStore.votingStatistics,
      volunteeringStatistics: s => s.memberStore.volunteeringStatistics,
      userElections: s => s.electionStore.userElections,
      selectedElection: s => s.electionStore.selectedElection
    }),
    ...mapGetters({
      user: 'user',
      getModules: 'getModules',
      allocation: 'memberStore/allocation'
    }),
    exportEmployeesUrl() {
      return `${ HTTP.defaults.baseURL }employees-overview-exports/${ this.user.profile_id.member_id }/${ this.selectedElection.id }`
    }
  },
  watch: {
    selectedElection: {
      async handler() {
        if (this.selectedElection?.id)
          await this.getStatistics()
      },
      deep: true
    }
  },
  async created() {
    await Promise.allSettled([
      this.getElections(),
      this.addModules()
    ])
    await Promise.allSettled([
      this.getHours(),
      this.getRegistrationURL(),
      this.getOrganizationOverview()
    ])
  },
  methods: {
    ...mapActions({
      getVotingStatistics: 'memberStore/getVotingStatistics',
      getVolunteeringStatistics: 'memberStore/getVolunteeringStatistics',
      getDonationStatistics: 'memberStore/getDonationStatistics',
      getRegistrationURL: 'employeeStore/getRegistrationURL',
      getUserElections: 'electionStore/getUserElections',
      getMemberHours: 'memberStore/getMemberHours',
      getOrgOverview: 'memberStore/getOrganizationOverview',
      addModules: 'addModules',
      crud: 'crud'
    }),
    async getStatistics() {
      this.loadingStatistics = true
      await Promise.allSettled([
        this.getVotingStatistics(this.selectedElection.id),
        this.getVolunteeringStatistics(),
        this.getDonationStatistics()
      ])
      this.loadingStatistics = false
    },
    showSections(data) {
      this.employeesByVoteStatus = data.employeesByVoteStatus
      this.showSectionEmployeesByVoteStatus = data.show
      if (data.sectionEmployeesByVoteTitle)
        this.sectionEmployeesByVoteTitle = data.sectionEmployeesByVoteTitle
      this.loadingEmployeeByVoteStatus = data.loading
      if (data.employeesByVoteStatus && data.show)
        this.scrollToSectionEmployeeByVote()
    },
    async copyUrl() {
      try {
        await navigator.clipboard.writeText(this.registrationURL)
        this.isUrlCopied = true
      } catch (e) {
        await helper.errorHandler(e)
      }
      setTimeout(() => this.isUrlCopied = false, 2000)
    },
    isModuleActive(module) {
      return this.getModules.includes(module)
    },
    async getElections() {
      this.loadingElections = true
      await this.getUserElections()
      this.loadingElections = false
    },
    async getHours() {
      if (this.isModuleActive('volunteering')) {
        this.loadingMemberHours = true
        await this.getMemberHours()
        this.loadingMemberHours = false
      }
    },
    async getOrganizationOverview() {
      const res = await this.getOrgOverview(this.user.profile_id.member_id)
      this.organization = res.jobTitles
      this.totalEmployees = res.totalEmployees
      this.loadingOrganization = false
    },
    formatNumber(num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    openDatePickerDialogFromEmployeesByVoteStatus() {
      let selectedForCommIds = []
      this.selectedEmployeeByVoteStatus.forEach((selected) => selectedForCommIds.push(selected.id))
      this.openDatePickerDialog(selectedForCommIds)
    },
    openDatePickerDialog(employeeIds) {
      this.selectedForSchedulingVoteEmployeeIds = employeeIds
      if (this.selectedForSchedulingVoteEmployeeIds.length === 0) {
        alert('You must select an employee')
        return
      }
      this.availableDates = []
      this.availableDates = this.getDateArray(
        this.selectedElection.start,
        this.selectedElection.end,
        this.selectedElection.earlyVoteStartDate,
        this.selectedElection.earlyVoteEndDate,
        this.selectedElection.voteDate
      )
      if (this.availableDates.length) {
        this.isScheduleVotingDialogOpen = true
      } else {
        this.snackbar({
          show: true,
          color: 'warning',
          message: 'There are no available dates in this election'
        })
      }
    },
    getDateArray(start, end, earlyStart, earlyEnd, voteDate) {
      // merge dates from early voting and vote day
      // or if all empty - from start of election to it end
      let dateArray = []
      if (earlyStart && earlyEnd) {
        let dates = this.getDaysArray(earlyStart, earlyEnd)
          .map(el => new Intl.DateTimeFormat('fr-ca').format(el))
        dateArray = [...dates]
      }
      if (voteDate) dateArray.push(new Intl.DateTimeFormat('fr-ca').format(new Date(voteDate)))
      if (!dateArray.length) {
        const dates = this.getDaysArray(start, end)
          .map(el => new Intl.DateTimeFormat('fr-ca').format(el))
        dateArray = [...dates]
      }
      return Array
        .from(new Set(dateArray
          .filter(el => new Date(new Date(el)) >= new Date(new Intl.DateTimeFormat('fr-ca').format(Date.now())))
          .sort((a, b) => new Date(a) - new Date(b)))
        )
    },
    getDaysArray(start, end) {
      let startDate = new Date(start)
      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
      let endDate = new Date(end)
      endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
      let datesArr = []
      for (let dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
        datesArr.push(new Date(dt))
      }
      return datesArr
    },
    scrollToSectionEmployeeByVote() {
      this.$smoothScroll({
        scrollTo: this.$refs.sectionEmployeeByVoteAnchor,
        duration: 1000,
        offset: -50
      })
    },
    sendCommunicationFromSelectedEmployeeByVote(ids) {
      let selectedForCommIds = []
      ids.forEach((selected) => selectedForCommIds.push(selected.id))
      if (selectedForCommIds.length === 0) {
        alert('You must select an employee')
        return
      }
      this.crud({
        dialog: true,
        title: 'New Communication',
        id: null,
        data: {
          type_send: 2,
          employees: selectedForCommIds,
          overviewEmployee: true,
          sendNow: true
        }
      })
    },
    closeDialog() {
      this.isScheduleVotingDialogOpen = false
      this.selectedEmployeeByVoteStatus = []
    }
  }
}
</script>
