<template>
  <div>
    <v-card class='py-3 px-5'>
      <div
        v-if='!loading'
        class='d-flex justify-space-between align-center'
      >
        <h3>
          {{ title }}
          <span class='ml-2 primary--text font-weight-bold text-capitalize'>
            {{ selectedElection.description }}
          </span>
        </h3>
        <v-btn
          :color='btnColor'
          class='text-capitalize font-weight-bold'
          outlined
          @click='isDialogOpen = true'
        >
          Change Election
        </v-btn>
      </div>
      <div
        v-else
        class='d-flex justify-space-between align-center'
      >
        <v-skeleton-loader type='text'></v-skeleton-loader>
        <v-skeleton-loader type='button'></v-skeleton-loader>
      </div>
    </v-card>
    <change-election-dialog
      :elections='elections'
      :is-open='isDialogOpen'
      :selected-election-id='selectedElection.id'
      @close-dialog='closeDialog'
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import ChangeElectionDialog from './change-election-dialog'

export default {
  name: 'change-election',
  components: { ChangeElectionDialog },
  props: {
    elections: {
      type: Array,
      required: true,
      default: () => []
    },
    selectedElection: {
      type: Object,
      required: true,
      default: () => ({})
    },
    btnColor: {
      type: String,
      default: 'primary'
    },
    title: {
      type: String,
      default: 'Change election title'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDialogOpen: false
    }
  },
  computed: {
    isElectionSelected() {
      return this.selectedElection && !!Object.keys(this.selectedElection).length
    },
    isElectionExist() {
      return this.elections?.length && this.elections.every(el => this.selectedElection.id !== el.id)
    }
  },
  watch: {
    elections: {
      handler() {
        this.checkSelectedElection()
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations({
      SET_SELECTED_ELECTION: 'electionStore/SET_SELECTED_ELECTION'
    }),
    checkSelectedElection() {
      if (!this.isElectionSelected || this.isElectionExist){
        let recentElection = this.elections[0];
        this.elections.forEach((value, index) => {
          if (index !== 0) {
            let valueDate = Date.parse(value.start)
            let recentDate = Date.parse(recentElection.start)
            if (valueDate > recentDate)
              recentElection = value
          }
        })
        this.SET_SELECTED_ELECTION(recentElection)
      }
      else {
        const updatedElection = this.elections.find(el => el.id === this.selectedElection.id)
        this.SET_SELECTED_ELECTION(updatedElection)
      }
    },
    closeDialog() {
      this.$emit('close-dialog')
      this.isDialogOpen = false
    }
  }
}
</script>

<style scoped>
::v-deep .v-skeleton-loader__text {
  height: 28px !important;
  width: 800px !important;
  margin-bottom: 0;
  background: rgba(91, 141, 215, .7) !important;
}
::v-deep .v-skeleton-loader__button {
  height: 36px !important;
  width: 160px !important;
  background: rgba(91, 141, 215, .7) !important;
}
@media (max-width: 1450px) {
  ::v-deep .v-skeleton-loader__text {
    width: 400px !important;
  }
}
@media (max-width: 1000px) {
  ::v-deep .v-skeleton-loader__text {
    width: 250px !important;
  }
}
@media (max-width: 800px) {
  ::v-deep .v-skeleton-loader__text {
    width: 450px !important;
  }
}
@media (max-width: 800px) {
  ::v-deep .v-skeleton-loader__button {
    display: none;
  }
}
</style>
