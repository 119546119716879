<template>
  <div v-frag>
    <v-card class='mx-3 py-3 px-5 mt-10'>
      <div
        v-if='!loading'
        class='d-flex justify-space-between align-center'
      >
        <h3>
          {{ title }}
          <span class='ml-2 primary--text font-weight-bold text-capitalize'>
            {{ totalHours }}
          </span>
        </h3>
        <v-btn
          class='text-capitalize font-weight-bold'
          color='primary'
          outlined
          @click='isDialogHoursOpen = true'
        >
          Set limit hours
        </v-btn>
      </div>
      <div
        v-else
        class='d-flex justify-space-between align-center'
      >
        <v-skeleton-loader type='text'></v-skeleton-loader>
        <v-skeleton-loader type='button'></v-skeleton-loader>
      </div>
    </v-card>
    <set-time-limits-dialog
      :is-open='isDialogHoursOpen'
      @close-dialog='isDialogHoursOpen = false'
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import SetTimeLimitsDialog from './set-time-limits-dialog.vue'

export default {
  name: 'time-limits',
  components: { SetTimeLimitsDialog },
  props: {
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDialogHoursOpen: false
    }
  },
  computed: {
    ...mapState('memberStore', ['memberHoursData']),
    totalHours() {
      return this.memberHoursData?.total_hours || 0
    }
  }
}
</script>

<style scoped>
::v-deep .v-skeleton-loader__text {
  height: 28px !important;
  width: 800px !important;
  margin-bottom: 0;
  background: rgba(91, 141, 215, .7) !important;
}
::v-deep .v-skeleton-loader__button {
  height: 36px !important;
  width: 160px !important;
  background: rgba(91, 141, 215, .7) !important;
}
@media (max-width: 1450px) {
  ::v-deep .v-skeleton-loader__text {
    width: 400px !important;
  }
}
@media (max-width: 1000px) {
  ::v-deep .v-skeleton-loader__text {
    width: 250px !important;
  }
}
@media (max-width: 800px) {
  ::v-deep .v-skeleton-loader__text {
    width: 450px !important;
  }
}
@media (max-width: 800px) {
  ::v-deep .v-skeleton-loader__button {
    display: none;
  }
}
</style>
