<template>
  <v-dialog
    v-model='isOpen'
    max-width='550'
    persistent
    transition='dialog-bottom-transition'
  >
    <v-card class='pa-5'>
      <v-card-title class='pa-0 mb-2'>
        <span class='headline card-title'>Annual hours to contribute to the community</span>
      </v-card-title>
      <v-card-subtitle class='pa-0 ma-0 mb-5'>
        Set an hour limit for your employees in order they can contribute to
        the community. The hour limit is for each employee in your company and
        will allow them to request time off to Volunteer. <br/>
        Note: This must be set up every year.
      </v-card-subtitle>
      <v-card-text class='pa-0'>
        <v-form
          ref='form'
          v-model='valid'
          lazy-validation
        >
          <p class='font-weight-bold mb-2'>
            Hours per employee in the {{ new Date().getFullYear() }} period.
          </p>
          <v-text-field
            v-model.number='hours'
            :rules="[v => (!!v || v === 0) || 'Field is required']"
            outlined
            type='number'
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class='d-flex justify-center pa-0'>
        <v-btn
          class='px-10 mr-8 font-weight-bold'
          color='primary'
          outlined
          @click='closeDialog'
        >
          Cancel
        </v-btn>
        <v-btn
          :loading='loading'
          class='px-10 ma-0 font-weight-bold'
          color='primary'
          @click='onSubmit'
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      hours: 0,
      valid: true,
      loading: false
    }
  },
  computed: mapState('memberStore', ['memberHoursData']),
  watch: {
    memberHoursData: {
      handler() {
        this.hours = this.memberHoursData?.total_hours || 0
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('memberStore', ['createMemberHours', 'updateMemberHours']),
    async onSubmit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.memberHoursData?.id
          ? await this.updateMemberHours({
            id: this.memberHoursData.id,
            total_hours: this.hours
          })
          : await this.createMemberHours({
            member_id: this.$store.getters.user.profile_id.member_id,
            total_hours: this.hours
          })
        this.loading = false
        this.$emit('close-dialog')
      }
    },
    closeDialog() {
      this.$emit('close-dialog')
      this.hours = this.memberHoursData.total_hours
    }
  }
}
</script>