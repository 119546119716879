<template>
  <v-card class='pa-5'>
    <v-data-table
      ref='dataTable'
      v-model='selectedTable'
      :expanded='expanded'
      :headers='organizationStatisticsHeaders'
      :items='subordinates'
      :loading='loadingSubordinates || loadingExpand'
      checkboxColor='primary'
      class='myTable'
      item-key='id'
      selectable-key='id'
      show-expand
      show-select
    >
      <template #top>
        <v-toolbar-title class='font-weight-bold mb-2'>
          {{ sections.my_organization_status || 'My Organization title' }}
        </v-toolbar-title>
        <div class='d-flex justify-center'>
          <v-btn
            :href='exportEmployeesUrl'
            class='primary--text text-capitalize mr-6'
            large
            outlined
          >
            Export Employee Status
            <v-icon
              class='white--text'
              color='primary'
              right
              small
            >
              mdi-export-variant
            </v-icon>
          </v-btn>
          <v-btn
            v-if="isModuleActive('voting')"
            class='primary--text text-capitalize mr-6'
            large
            outlined
            @click='openScheduleVotingDialog'
          >
            Schedule voting
          </v-btn>
          <v-btn
            class='primary--text text-capitalize'
            large
            outlined
            @click='sendCommunication'
          >
            Send Communication
          </v-btn>
        </div>
        <v-switch
          class="d-inline-block"
          v-model='expandValue'
          :disabled='loadingSubordinates'
          :label='`${expandValue.toString()}`'
          :loading='loadingExpand'
          false-value='Collapsed'
          inset
          true-value='Expanded'
          @click='expandAllTable'
        ></v-switch>
      </template>
      <template #header.dayScheduledToVote='{ header }'>
        <div class='d-flex align-center'>
          <span>{{ header.text }}</span>
          <v-tooltip
            max-width='300'
            top
          >
            <template #activator='{ on, attrs }'>
              <v-icon
                class='ml-2 tooltip-icon'
                size='20'
                v-bind='attrs'
                v-on='on'
              >
                mdi-eye
              </v-icon>
            </template>
            <div class='text-center'>
              If an employee is marked N/A. they do not have a voter guide for this election.
            </div>
          </v-tooltip>
        </div>
      </template>
      <template #item.data-table-expand='data'>
        <v-btn
          v-if="data.item.name.includes('(')"
          :loading='loadingSingleExpand && data.item.id === employeeId'
          icon
          @click='populateTreeview(data)'
        >
          <v-icon :class="{'v-data-table__expand-icon--active': data.isExpanded }">
            $expand
          </v-icon>
        </v-btn>
      </template>
      <template #item.empReg='{ item }'>
        <span
          :class="{
            'success--text': item.empReg === 100,
            'warning--text': item.empReg < 100 && item.empReg >= 50,
            'error--text': item.empReg < 50,
          }"
        >
          {{ item.empReg }} %
        </span>
      </template>
      <template #item.empVot='{ item }'>
        <span
          :class="{
            'success--text': item.empVot === 100,
            'warning--text': item.empVot < 100 && item.empVot >= 50,
            'error--text': item.empVot < 50,
          }"
        >
          {{ item.empVot }} %
        </span>
      </template>

      <template #expanded-item='{ headers, item }'>
        <td
          :colspan='headers.length'
          class='ma-0 td'
        >
          <v-treeview
            ref='treeview'
            v-model='item.selectedTreeview'
            :items='item.children'
            :open-all='expandTreeview'
            expand-icon='$expand'
            hoverable
            item-key='id'
            selectable
            selected-color='#5b8dd7'
            selection-type='independent'
            transition
          >
            <template #label={item}>
              <div class='rowCustom'>
                <div class='item'>{{ item.name }}</div>
                <div class='item'>{{ item.jobTitle }}</div>
                <div
                  :style="{ paddingLeft: item.reg==='No' && 3+'px' }"
                  class='item'
                >
                  {{ item.reg }}
                </div>
                <div class='item'>{{ item.dayScheduledToVote }}</div>
                <div class='item'>{{ item.votedDate }}</div>
                <div
                  :class="{
                  'success--text': item.empReg === 100,
                  'warning--text': item.empReg < 100 && item.empReg >= 50,
                  'error--text': item.empReg < 50,
                  }"
                  class='item'
                >{{ item.empReg }} %
                </div>
                <div
                  :class="{
                  'success--text': item.empVot === 100,
                  'warning--text': item.empVot < 100 && item.empVot >= 50,
                  'error--text': item.empVot < 50,
                  }"
                  class='item'
                >{{ item.empVot }} %
                </div>
              </div>
            </template>
          </v-treeview>
        </td>
      </template>
    </v-data-table>
    <schedule-voting-dialog
      :available-dates='availableDates'
      :is-open='isScheduleVotingDialogOpen'
      :selected-ids='selectedEmployeesIds'
      @update-table='initSubordinates'
      @close-dialog='closeDialog'
    />
    <dialog-communication
      @update-table='initSubordinates'
      @clear-selected='clearSelected'
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ScheduleVotingDialog from './dialogs/schedule-voting-dialog'
import DialogCommunication from '@/views/communication/components/DialogCommunication'

export default {
  name: 'organization-status',
  components: { DialogCommunication, ScheduleVotingDialog },
  data() {
    return {
      loadingSubordinates: true,
      loadingExpand: false,
      loadingSingleExpand: false,
      expandTreeview: false,
      employeeId: -1,
      selectedTable: [],
      selectedEmployeesIds: [],
      expanded: [],
      organizationStatisticsHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '20%'
        },
        {
          text: 'Job Title',
          sortable: false,
          value: 'jobTitle'
        },
        {
          text: 'Reg',
          sortable: false,
          value: 'reg'
        },
        {
          text: 'Day Scheduled to Vote',
          sortable: false,
          value: 'dayScheduledToVote'
        },
        {
          text: 'Day Voted',
          sortable: false,
          align: 'center',
          value: 'votedDate'
        },
        {
          text: 'Emp. Reg',
          sortable: false,
          align: 'center',
          value: 'empReg'
        },
        {
          text: 'Emp. Vot',
          align: 'center',
          sortable: false,
          value: 'empVot'
        }
      ],
      expandValue: 'Collapsed',
      isScheduleVotingDialogOpen: false,
      availableDates: []
    }
  },
  computed: {
    ...mapState({
      sections: s => s.cmsStore.sections,
      selectedElection: s => s.electionStore.selectedElection,
      subordinates: s => s.memberStore.subordinates
    }),
    ...mapGetters({
      user: 'user',
      getModules: 'getModules'
    }),
    exportEmployeesUrl() {
      return `${ process.env.VUE_APP_BASE_URL }employees-overview-exports/${ this.user.profile_id.member_id }/${ this.selectedElection.id }`
    },
    isMember() {
      return this.user.id_rol === 2
    }
  },
  watch: {
    selectedElection: {
      async handler() {
        if (this.selectedElection.id) {
          await this.initSubordinates()
          this.clearSelected()
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getSubordinates: 'memberStore/getSubordinates',
      getSubordinatesView: 'memberStore/getSubordinatesView',
      snackbar: 'snackbar',
      crud: 'crud'
    }),
    ...mapMutations({
      REFRESH_SUBORDINATES: 'memberStore/REFRESH_SUBORDINATES'
    }),
    checkSelectedEmployees() {
      if (!this.selectedEmployeesIds?.length) {
        this.snackbar({
          show: true,
          color: 'warning',
          message: 'You must select an employee'
        })
        return false
      }
      return true
    },
    setEmployeesIds() {
      this.selectedEmployeesIds = []
      if (this.selectedTable?.length) {
        this.selectedTable.forEach(el => this.selectedEmployeesIds = [...this.selectedEmployeesIds, el.id])
      }
      this.subordinates.forEach(el => {
        if (el.selectedTreeview?.length) this.selectedEmployeesIds = [...this.selectedEmployeesIds, ...el.selectedTreeview]
      })
    },
    async initSubordinates() {
      this.loadingSubordinates = true
      this.expanded = []
      this.selectedTable = []
      await this.getSubordinates(this.selectedElection.id)
      this.REFRESH_SUBORDINATES()
      this.loadingSubordinates = false
    },
    isModuleActive(module) {
      return this.getModules.includes(module)
    },
    async openScheduleVotingDialog() {
      this.availableDates = []
      this.availableDates = this.getDateArray(
        this.selectedElection.start,
        this.selectedElection.end,
        this.selectedElection.earlyVoteStartDate,
        this.selectedElection.earlyVoteEndDate,
        this.selectedElection.voteDate,
        this.selectedElection.firstWeekEndDate
      )
      this.setEmployeesIds()
      if (this.checkSelectedEmployees()) {
        if (this.availableDates.length)
          this.isScheduleVotingDialogOpen = true
        else {
          await this.snackbar({
            show: true,
            color: 'warning',
            message: 'There are no available dates in this election'
          })
        }
      }
    },
    getDateArray(start, end, earlyStart, earlyEnd, voteDate, firstWeek) {
      let dateArray = []
      if (new Date(Date.now()).getTime() <= new Date(firstWeek.replace(/-/g, '/')).getTime() && !this.isMember) {
        let dates = this.getDaysArray(earlyStart, firstWeek).map(el => this.formatDate(el))
        dateArray = [...dates]
      } else {
        if (earlyStart && earlyEnd) {
          let dates = this.getDaysArray(earlyStart, earlyEnd).map(el => this.formatDate(el))
          dateArray = [...dates]
        }
        if (voteDate) dateArray.push(this.formatDate(new Date(voteDate.replace(/-/g, '/'))))
        if (!dateArray) {
          const dates = this.getDaysArray(start, end).map(el => this.formatDate(el))
          dateArray = [...dates]
        }
      }
      return Array.from(new Set(dateArray.filter(el => new Date(new Date(el).setMilliseconds(6 * 3600 * 1000)) >= new Date(Date.now()).setHours(0, 0, 0, 0))))
    },
    getDaysArray(start, end) {
      let startDate = new Date(start.replace(/-/g, '/'))
      startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
      let endDate = new Date(end.replace(/-/g, '/'))
      endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
      let datesArr = []
      for (let dt = startDate; dt <= endDate; dt.setDate(dt.getDate() + 1)) {
        datesArr.push(new Date(dt))
      }
      return datesArr
    },
    formatDate(date) {
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date)
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date)
      let day = new Intl.DateTimeFormat('en', { day: 'numeric' }).format(date)
      return `${ month } ${ day }, ${ year }`
    },
    sendCommunication() {
      this.setEmployeesIds()
      if (this.checkSelectedEmployees()) {
        this.crud({
          dialog: true,
          title: 'New Communication',
          id: null,
          data: {
            type_send: 2,
            employees: this.selectedEmployeesIds,
            overviewEmployee: true,
            sendNow: true
          }
        })
      }
    },
    async expandAllTable() {
      if (this.expandValue === 'Expanded') {
        this.expandTreeview = false
        this.loadingExpand = true
        this.expanded = []
        const itemsToExpand = []
        for (const el of this.subordinates) {
          this.populateTreeview({ item: el })
          if (el.name.includes('('))
            itemsToExpand.push(el)
        }
        this.expanded = itemsToExpand
        this.expandTreeview = true
        this.loadingExpand = false
      } else {
        this.expanded = []
        this.expandTreeview = false
      }
    },
    populateTreeview({ item, expand, isExpanded }) {
      this.employeeId = item.id
      if (isExpanded || isExpanded === false)
        expand(!isExpanded)
      if (isExpanded)
        this.expandValue = 'Collapsed'
      this.loadingSingleExpand = false
      this.expandTreeview = false
    },
    closeDialog() {
      this.isScheduleVotingDialogOpen = false
      this.clearSelected()
    },
    clearSelected() {
      this.expandValue = 'Collapsed'
      this.selectedTable = []
      this.subordinates.forEach(el => el.selectedTreeview = [])
    }
  }
}
</script>

<style lang='scss'>
.v-treeview-node__root button {
  width: 0;
}
div.v-treeview-node__level {
  width: 10px;
}
.v-application--is-ltr {
  .v-treeview-node__toggle {
    transform: rotate(0deg) !important;
  }
  .v-treeview-node__checkbox {
    margin-left: 25px !important;
  }
  .v-treeview-node__toggle--open {
    transform: rotate(-180deg) !important;
  }
}
div.v-application--is-ltr .v-treeview-node__content {
  margin-left: 0;
}
div.v-treeview-node__root {
  padding-left: 10px;
}
.td {
  padding-left: 110px !important;
}
.v-treeview-node__level + .v-treeview-node__checkbox {
  margin-left: 15px;
}
.v-application--is-ltr .myTable.v-data-table > .v-data-table__wrapper > table > thead > tr > th > .v-simple-checkbox {
  visibility: hidden;
}
.myTable {
  width: 100%;
  .v-data-table__wrapper {
    width: 100%;
    table {
      width: 1200px !important;
      margin: 0 auto;
    }
  }
}
.item {
  position: absolute;
  top: 15px;
  &:first-child {
    margin-left: 15px;
  }
  &:nth-child(2) {
    left: 278px;
  }
  &:nth-child(3) {
    left: 446px;
  }
  &:nth-child(4) {
    left: 566px;
  }
  &:nth-child(5) {
    left: 792px;
  }
  &:nth-child(6) {
    left: 922px;
  }
  &:nth-child(7) {
    left: 1026px;
  }
}
.tooltip-icon {
  cursor: pointer;
  opacity: 0.5;
  transition: .5s;
  &:hover {
    opacity: 1;
  }
}
</style>
