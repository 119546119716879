<template>
  <div v-frag>
    <v-dialog
      v-model='crudTwo.dialog'
      max-width='1000px'
      persistent
      transition='dialog-bottom-transition'>
      <v-card class='pa-5'>
        <v-card-title class='pl-0'>
          <span>{{ crudTwo.title }}</span>
        </v-card-title>
        <v-data-table
          v-model='selected'
          :headers='headers'
          :items='desserts'
          :loading='loading'
          class='pb-2'
        >
          <template v-slot:[`item.date`]='{ item }'>
            <span>{{ item.date }}</span>
          </template>
          <template v-slot:[`item.task`]='{ item }'>
            <div v-for='(row,i) in item.data'
                 :key='i'
                 class='d-flex pt-2 pb-2 checkbox align-center'>
              <v-checkbox v-model='row.completed'
                          class='pt-0'
                          @change='completedList(row)'
              ></v-checkbox>
              {{ i + 1 + '.' }}
              <span class='ml-1'>{{ row.description }}</span>
            </div>
          </template>
        </v-data-table>
        <v-card-actions class='justify-end'>
          <v-btn class='px-14 mx-4 font-weight-bold primary'
                 large
                 @click='closeDialog'>
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import task from '../../../services/task'

export default {
  computed: {
    crudTwo() {
      if (this.$store.getters.crudTwo.dialog) {
        this.initialLoad()
      }
      return this.$store.getters.crudTwo
    }
  },
  data: () => ({
    desserts: [],
    loading: false,
    selected: [],
    headers: [
      {text: 'Date', align: 'start', value: 'date'},
      {text: 'Task', align: 'start', value: 'task', sortable: false, width: '800px'}
    ]
  }),
  methods: {
    async initialLoad() {
      await this.getData()
    },
    async getData() {
      this.loading = true
      try {
        this.desserts = await task.indexTask(this.$store.getters.crudTwo.id)
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async completedList(item) {
      try {
        await task.completedTask(item.id, item.completed)
        await this.$store.dispatch('eventBus', true)
      } catch (e) {
        console.log(e)
      }
    },
    closeDialog() {
      this.$store.dispatch('crudTwo', {
        dialog: false,
        title: '',
        id: null,
        data: {}
      })
    }
  }
}
</script>
<style lang='scss'>
.border {
  border-right: thin solid rgba(0, 0, 0, 0.12);
}
.checkbox > * {
  margin: 0 !important;
  .v-messages {
    min-height: 0;
  }
  .v-input__slot {
    margin: 0 !important;
  }
}
</style>