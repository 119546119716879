<template>
  <div v-frag>
    <v-card class='mx-3 px-6 py-6 mt-10'>
      <v-row align='center' justify='space-between' no-gutters>
        <v-col cols='12' lg='8' md='8' sm='12' xl='8' xs='12'>
          <h3>Campaign Checklist</h3>
          <span>
            <b>Today Task:</b> You`ve completed
            <b :class='data.color'>{{ data.completed }}/{{ data.limit }}</b>.
            <a @click='openDialogTask'>Open your campaign checklist begin now.</a>
          </span>
        </v-col>
        <v-col cols='12' lg='4' md='4' sm='12' xl='4' xs='12'>
          <v-btn
            :disabled='disabled'
            class='text-capitalize font-weight-bold float-right'
            color='primary'
            @click='openDialogTask'
          >
            View Checklist
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <list-check-list/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import task from '../../../services/task'
import ListCheckList from './ListCheckList.vue'

export default {
  components: { ListCheckList },
  props: {
    election: {
      type: Object
    }
  },
  watch: {
    election() {
      if (this.election.id) {
        this.getDetail(this.election.id)
      }
    }
  },
  data: () => ({
    data: {
      completed: '-',
      limit: '-',
      color: 'red--text'
    },
    disabled: true
  }),
  methods: {
    ...mapActions({
      crudTwo: 'crudTwo'
    }),
    async getDetail() {
      if (this.election.id) {
        this.disabled = true
        try {
          const res = await task.getDetailCard(this.election.id)
          if (res.success) {
            this.disabled = false
          }
          this.data = res
        } catch (e) {
          console.log(e)
        }
      }
    },
    openDialogTask() {
      if (!this.disabled) {
        this.crudTwo({
          dialog: true,
          title: 'Campaign Checklist',
          id: this.election.id,
          data: {}
        })
      }
    }
  }
}
</script>
